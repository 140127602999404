import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout/layout";

export const query = graphql`
  query CategoryQuery($slug: String!) {
    strapiCategory(slug: { eq: $slug }) {
        name
        slug
        articles {
            slug
            title
            publishedAt(formatString: "LL", locale: "lt")
            image {
                childImageSharp {
                    fixed(width: 700, height: 500) {
                        src
                    }
                }
            }
        }
    }
  }
`;

const Category = ({ data }) => {
    let category = data.strapiCategory
    return <>
        <Layout>
            {category.name} {category.slug}
        </Layout>
    </>

};

export default Category;
